import React, { Component } from 'react';
import Layout from './layout';

class HireMe extends Component {
  constructor() {
    super();
    this.state = {
      content: [],
      error: null,
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    fetch('https://bottomlesssea-api.azurewebsites.net/api/HireMe/1', {
      method: 'GET',
      headers: new Headers({
        "Access-Control-Allow-Origin" : "*"
      })
    }).then(res => {
      if (res.status >= 400) {
        throw new Error("Server responds with error " + res.status + " (" + res.statusText + ")");
      }
      return res.json();
    }).then(content => {
      this.setState({ content: content, loading: false });
    }, err => {
      this.setState({
        error: err,
        loading: false
      });
    });
  }

  render() {
    let { content, error, loading } = this.state;
    if (error) {
      return (<div className="space-top">{error.message}</div>)
    }
    if (loading) {
      return (<div className="space-top">Loading...</div>)
    }
    return (<Layout data={content} />);
  }
}

export default HireMe;