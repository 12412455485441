import React, { Component } from 'react';
import Cell from './cell.js'

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tail: false,
      board: []
    };

    const height = this.props.height;
    const width = this.props.width;

    let rowKey = 0;
    let cellKey = 0;

    for (let w = 0; w < width; w++) {
      let row = { rowKey: rowKey, cells: [] }
      for (let h = 0; h < height; h++) {
        const cell = this.createCell(rowKey, cellKey, false, false, false, false, 'game-cell');
        row.cells.push(cell);
        cellKey++;
      }
      this.state.board.push(row);
      rowKey++;
    }
  }

  componentWillReceiveProps({ wallInput, startInput, finishInput }) {
    this.setState({ wallInput: wallInput, startInput: startInput, finishInput: finishInput });
  }

  createCell(rowKey, cellKey, wall, player, target, tail, className) {
    return { cellKey: cellKey, wall: wall, player: player, target: target, tail: tail, content: this.renderCell(rowKey, cellKey, className) };
  }

  renderCell = (rowKey, cellKey, className) => {
    return (<Cell key={cellKey} className={className} onClick={() => this.updateCellState(rowKey, cellKey)} />);
  }

  updateCellState = (rowKey, cellKey) => {
    // Find the row/cell that needs to be updated.
    let data = this.state.board;
    let rowIdx = data.findIndex(x => x.rowKey === rowKey);
    let row = data[rowIdx];
    let cellIdx = row.cells.findIndex(x => x.cellKey === cellKey);

    if (this.state.wallInput) {
      if (data[rowIdx].cells[cellIdx].wall) {
        data[rowIdx].cells[cellIdx] = this.createCell(rowKey, cellKey, false, false, false, false, 'game-cell');
      } else {
        data[rowIdx].cells[cellIdx] = this.createCell(rowKey, cellKey, true, false, false, false, 'game-cell wall');
      }
    } else if (this.state.startInput) {
      // clear the existing start and add new one
      data.forEach(r => {
        r.cells.forEach(c => {
          if (c.player) {
            c.player = false; c.content = this.renderCell(r.rowKey, c.cellKey, 'game-cell');
          }
        })
      });
      data[rowIdx].cells[cellIdx] = this.createCell(rowKey, cellKey, false, true, false, false, 'game-cell player');
    } else if (this.state.finishInput) {
      // clear the existing start and add new one
      data.forEach(r => {
        r.cells.forEach(c => {
          if (c.target) {
            c.target = false; c.content = this.renderCell(r.rowKey, c.cellKey, 'game-cell');
          }
        })
      });
      data[rowIdx].cells[cellIdx] = this.createCell(rowKey, cellKey, false, false, true, false, 'game-cell target');
    }
    this.setState({ board: data });
  }

  render() {
    return (
      <div className="game-board">
        {this.state.board.map((row) => <div key={row.rowKey} className="game-row">{row.cells.map((cell) => cell.content)}</div>)}
      </div>
    );
  }
}

export default Board;