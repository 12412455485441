import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './content/styles/stylesheets/styles.css';

import 'bootstrap/dist/js/bootstrap.min'; // todo: Remove this and rely on react-bootstrap module instead! ************
import Header from './components/header';
import NavBar from './components/navBar';
import Home from './components/home';
import HireMe from './components/hireMe';
import GameController from './components/games/game-controller';

class App extends Component {
  render() {
    return (
      <Container>
        <Router>
          <Header />
          <NavBar />
          <div className="app">
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/hireme" component={HireMe} />
              <Route path="/astar" component={GameController} />
            </Switch>
          </div>
        </Router>
      </Container>
    );
  }
}

export default App;