import React from 'react';
import { AiFillLinkedin, AiFillGithub, AiFillTwitterCircle } from 'react-icons/ai';

function Header() {
  return (
    <div className='header'>

      <a href='https://www.linkedin.com/in/anthony-ward-se/' target='_blank' className='logo'><AiFillLinkedin /></a>
      <a href='https://github.com/Tikeb/' target='_blank' className='logo' ><AiFillGithub /></a>
      <a href='https://twitter.com/bottomless_sea' target='_blank' className='logo'><AiFillTwitterCircle /></a>
    </div>
  );
}

export default Header;