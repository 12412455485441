import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

class NavBar extends Component {
  NavLinkHome() {
    return (
      <LinkContainer to="/" exact>
        <Nav.Link>HOME</Nav.Link>
      </LinkContainer>
    );
  }

  NavLinkCovid() {
    return (
      <LinkContainer to="/covid">
        <Nav.Link>COVID</Nav.Link>
      </LinkContainer>
    );
  }

  NavLinkaStar() {
    return (
      <LinkContainer to="/astar">
        <Nav.Link>PROJECTS</Nav.Link>
      </LinkContainer>
    );
  }

  NavLinkHireMe() {
    return (
      <LinkContainer to="/hireme">
        <Nav.Link>HIRE ME</Nav.Link>
      </LinkContainer>
    );
  }

  NavGamesDropDown() {
    return (
      <NavDropdown title="PROJECTS" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">ISOLA</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">FLOOD</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">TIC-TAC-TOE</NavDropdown.Item>
      </NavDropdown>
    );
  }

  render() {
    return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand>BOTTOMLESS SEA</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={this.props.location.pathname}>
            {this.NavLinkHome()}
            {/* {this.NavLinkCovid()}
            {this.NavLinkaStar()} */}
          </Nav>
          <Nav activeKey={this.props.location.pathname}>
            {this.NavLinkHireMe()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(NavBar);