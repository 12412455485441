import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

class ResetAllInput extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      //showModal: props.closeModal
    };
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    return (
      <div className="space-top">
        <hr />
        <Button variant="danger" block onClick={() => this.toggleModal()}>Reset All</Button>

        <Modal size="lg" show={this.state.showModal} centered onHide={() => this.toggleModal()}>
          <Modal.Body>
            <Container>
              <Row>
                <Col md="auto" style={{ marginTop: "5px" }}>
                  Are you sure? This will clear all walls and start/finish positions.
              </Col>
                <Col>
                  <div className="float-right">
                    <Button variant="secondary" onClick={() => this.toggleModal()} style={{ marginRight: "15px" }}>Cancel</Button>
                    <Button variant="primary" onClick={() => this.toggleModal()}>OK</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ResetAllInput;