import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap'
import { TiTickOutline, TiTimesOutline } from 'react-icons/ti';
var parse = require('html-react-parser');

function GenerateHeader(colInfo) {
  let icon = '';
  switch (colInfo.flag) {
    case 1:
      icon = <TiTickOutline className='colour--tick' />;
      break;
    case 2:
      icon = <TiTimesOutline className='colour--cross' />;
      break;
  }
  return (
    <h3>
      <small className='text-muted'>{colInfo.header} {icon}</small>
    </h3>
  );
}

function GenerateBody(colInfo) {
  if (colInfo.contentBody != undefined && colInfo.contentBody.length > 0) {
    const bodyData = colInfo.contentBody.sort((a, b) => { return a.position - b.position });

    //console.log(bodyData);

    let content = [];
    let list = [];

    bodyData.forEach(x => {
      const parsedData = parse(x.description);

      console.log(parsedData);

      switch (x.type) {
        case 1: // Div
          content.push(<div>{parsedData}</div>);
          break;
        case 2: // List
          list.push(<li>{parsedData}</li>);
          break;
        case 3: // Paragraph
          content.push(<p>{parsedData}</p>);
          break;
        default:
          content.push(parsedData);
          break;
      }
    });

    if (list.length > 0) {
      content.push(<ul>{list}</ul>);
    }

    if (colInfo.hero) {
      return (<h1>{content}</h1>);
    } else {
      return (<div>{content}</div>);
    }
  }
  return (<></>);
}

function CreateCol(colInfo) {
  return (
    <Col key={colInfo.id} lg={colInfo.width} className='col--lg'>
      <div className='boxes'>
        {GenerateHeader(colInfo)}
        {GenerateBody(colInfo)}
      </div>
    </Col>
  );
}

function CreateRow(cols, key) {
  return (
    <Row key={key}>{cols}</Row>
  );
}

function CreatePage(data) {
  let rows = [];
  let cols = [];
  let width = 0;

  if (data != undefined && data.length > 0) {
    data.forEach(function (item, idx) {
      let col = data[idx];
      width = width + col.width;
      cols.push(CreateCol(col));

      if (width >= 12 || data.length - 1 === idx) {
        width = 0;
        rows.push(CreateRow(cols, idx));
        cols = [];
      }
    });

    return (rows);
  } else {
    return (<div>No data found..</div>);
  }
}

class Layout extends Component {
  render() {
    const data = this.props.data.sort((a, b) => { return a.position - b.position });
    return (
      CreatePage(data)
    );
  }
}

export default Layout;