import React, { Component } from 'react';

class RunGameInput extends Component {
  render() {
    return (
      <div className="space-top">

        <div className="row space-top">
          <div className="col">
            <button type="button" className="btn btn-secondary" onClick={this.props.onClickReset}>Reset</button>
          </div>
          <div className="col">
            <button type="button" className="btn btn-primary float-right" onClick={this.props.onClickRun}>Run</button>
          </div>
        </div>
      </div>);
  }
}

export default RunGameInput;