import React, { Component } from 'react';
import Board from './board/board';
import WallsInput from './settings/wallsInput'
import StartFinishInput from './settings/startFinishInput';
import ResetAllInput from './settings/resetAllInput';
import RunGameInput from './settings/runGameInput';

import $ from 'jquery';
window.jQuery = $;
require('bootstrap-input-spinner/src/bootstrap-input-spinner.js');

class GameController extends Component {
  constructor() {
    super();
    this.state = {
      wallInputClass: 'btn btn-dark',
      wallInput: false,
      playerInputClass: 'btn btn-dark',
      playerInput: false,
      targetInputClass: 'btn btn-dark float-right',
      targetInput: false,
      closeModal: false
    }
  }

  jQueryStartupScripts = () => {
    $('[data-toggle="popover"]').popover();
    //$("input[type='number']").inputSpinner({ template: '<div class="input-group ${groupClass}"><div class="input-group-prepend"></div><div class="input-type-number"><input type="text" inputmode="decimal" style="text-align: ${textAlign}" class="form-control"/></div><div class="input-group-append"><button style="min-width: ${buttonsWidth}" class="btn btn-decrement ${buttonsClass}" type="button">${decrementButton}</button><button style="min-width: ${buttonsWidth}" class="btn btn-increment ${buttonsClass}" type="button">${incrementButton}</button></div></div>' });

    // Convert this from jQuery into JSX
    $('body').on('click', function (e) {
      $('[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          $(this).popover('hide');
        }
      });
    });
  }

  componentDidMount() {
    this.jQueryStartupScripts();
  }

  setWallsMode = () => {
    this.setState({ playerInputClass: 'btn btn-dark', playerInput: false });
    this.setState({ targetInputClass: 'btn btn-dark float-right', targetInput: false });

    if (!this.state.wallInput) {
      this.setState({ wallInputClass: 'btn btn-warning', wallInput: true });
    } else {
      this.setState({ wallInputClass: 'btn btn-dark', wallInput: false });
    }
  }

  setStartMode = () => {
    this.setState({ wallInputClass: 'btn btn-dark', wallInput: false });
    this.setState({ targetInputClass: 'btn btn-dark float-right', targetInput: false });

    if (!this.state.playerInput) {
      this.setState({ playerInputClass: 'btn btn-success', playerInput: true });
    } else {
      this.setState({ playerInputClass: 'btn btn-dark', playerInput: false });
    }
  }

  setFinishMode = () => {
    this.setState({ wallInputClass: 'btn btn-dark', wallInput: false });
    this.setState({ playerInputClass: 'btn btn-dark', playerInput: false });

    if (!this.state.targetInput) {
      this.setState({ targetInputClass: 'btn btn-danger float-right', targetInput: true });
    } else {
      this.setState({ targetInputClass: 'btn btn-dark float-right', targetInput: false });
    }
  }

  resetBoard = () => {
    this.setState({ closeModal: true });
    alert('Are you sure you want to reset?');
  }

  runGameCommand = () => {
    alert('Game running...');
  }

  render() {
    return (
      <div className="board-container row justify-content-md-center">
        <div className="col col-lg-2">
          <div className="form-group">
            <h3>Settings</h3>
            <WallsInput
              className={this.state.wallInputClass}
              onClick={() => this.setWallsMode()}
            />
            <StartFinishInput
              startClassName={this.state.playerInputClass}
              finishClassName={this.state.targetInputClass}
              onClickStart={() => this.setStartMode()}
              onClickFinish={() => this.setFinishMode()}
            />
            <ResetAllInput
              closeModal={this.state.closeModal}
              onClick={() => this.resetBoard()}
            />
            <RunGameInput
              onClickReset={() => this.resetBoard()}
              onClickRun={() => this.runGameCommand()}
            />
          </div>
        </div>
        <div className="col-md-auto">
          <Board
            width={8}
            height={8}
            wallInput={this.state.wallInput}
            startInput={this.state.playerInput}
            finishInput={this.state.targetInput}
          />
        </div>
      </div>
    )
  }
}

export default GameController;