import React, { Component } from 'react';

class Cell extends Component {
  constructor() {
    super();
    this.state = {
      className: 'game-cell'
    }
  }

  render() {
    return (<div className={this.props.className} onClick={this.props.onClick} ></div>);
  }
}

export default Cell;