import React, { Component } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

class WallsInput extends Component {
  render() {
    return (
      <div className="space-top">
        <label>Place walls <AiOutlineInfoCircle className="hover-hand" data-toggle="popover" data-content="Click once to enter wall placing mode, click again to finish" /></label>
        <div className="row">
          <div className="col">
            <button type="button" className={this.props.className} onClick={this.props.onClick}>Place Walls</button>
          </div>
        </div>
      </div>
    );
  }
}

export default WallsInput;